/* Navbar Container */
.navbar {
  background-color: #111;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

/* Logo Styling */
.sc-logo {
  font-size: 1.2rem;
  font-weight: bold;
  background: linear-gradient(to right, #FFC107, #FF5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

/* Nav Links */
.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-links li a {
  color: #ffc107;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #fff;
}

/* Menu Icon Styling */
.menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.menu-line {
  width: 25px;
  height: 3px;
  background-color: #ffc107;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    gap: 10px;
    background-color: #111;
    position: absolute;
    top: 60px;
    right: 10px;
    padding: 10px;
    border-radius: 5px;
  }

  .nav-links.open {
    display: flex;
  }

  .menu-icon {
    display: flex;
  }
}
