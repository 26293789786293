body {
    margin: 0;
    padding: 0;
    background-color: #111;
    min-height: 100vh; /* Allow content to exceed viewport height */
    overflow-y: auto; /* Enable scrolling for overflowing content */
}

html {
    height: auto; /* Allow flexible height */
}

.content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible; /* Allow overflowing content */
    padding: 20px; /* Consistent padding around content */
  }
  
  .section-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px; /* Consistent padding */
    min-height: calc(100vh - 80px); /* Adjust for navbar height (replace 80px with your navbar height) */
    overflow-y: auto; /* Allow scrolling if content exceeds viewport height */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    box-sizing: border-box; /* Ensure padding doesn't add extra width */
  }
  