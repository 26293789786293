/* Section Container */
.section-container {
  margin: 40px auto;
  padding: 20px;
  max-width: 1200px; /* Centralize the content */
  text-align: left;
}

.section-container .content-header h2 {
  font-size: 32px;
}

.section-container .content-header p {
  font-size: 22px;
}

/* Header Styling */
.content-header {
  margin-bottom: 20px;
}

.content-header h2 {
  font-size: 32px; /* 可以調整此字型大小以適配較小螢幕 */
  margin-bottom: 15px;
  color: #ffc107;
  font-weight: bold;
  text-align: center;
  word-wrap: break-word; /* 確保長文字自動換行 */
  overflow-wrap: break-word; /* 支援所有瀏覽器的文字換行 */
  white-space: normal; /* 禁止文字一行顯示，允許換行 */
  line-height: 1.9; /* 調整行高以防止文字擁擠 */
}

.content-header p {
  font-size: 20px;
  line-height: 1.8;
  color: #eaeaea;
  margin-bottom: 1em;
  text-align: justify; /* Align text for better readability */
}

/* Main Image Section */
.main-image-container {
  text-align: center;
  margin: 20px 0;
}

.image-video-container {
  display: flex; /* Arrange items in a row */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Add space between image and video */
  gap: 20px; /* Space between the image and video */
  margin: 20px 0; /* Add space above and below */
}

.image-container-left {
  flex: 1; /* Allow the image to take up equal space */
  display: flex;
  justify-content: center; /* Center the image horizontally */
}

.image-container {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  margin: 20px 0; /* Add some spacing */
}

.single-image {
  max-width: 100%; /* Ensure the image scales to fit its container */
  height: auto; /* Maintain the aspect ratio */
  border-radius: 10px; /* Optional: add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: add a shadow effect */
}

.main-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
}

.main-image:hover {
  transform: scale(1.05);
}

/* Gallery Section */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.gallery-image {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
}

.gallery-image:hover {
  transform: scale(1.05);
}

/* Responsive Iframe */
.responsive-iframe {
  width: 100%;
  max-width: 48%; /* Side-by-side on desktop */
  aspect-ratio: 16 / 9;
  border: none;
}

/* GIF Container */
.gif-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Ensure proper alignment on all screens */
  gap: 10px;
  margin-bottom: 20px;
}

.gif {
  width: 48%; /* Consistent width */
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.gif:hover {
  transform: scale(1.05);
}

/* Video Container */
.video-image-container {
  display: flex; /* Use flexbox for side-by-side layout */
  justify-content: space-between; /* Evenly space video and image */
  align-items: center; /* Vertically align video and image */
  gap: 20px; /* Add space between video and image */
  margin: 20px 0; /* Add space above and below */
}

/* Video container */
.video-container-right {
  flex: 1; /* Allow the video to take up equal space */
  display: flex;
  justify-content: center; /* Center the video horizontally */
}

.video-image-container-left {
  display: flex; /* Flexbox for alignment */
  justify-content: flex-start; /* Align to the left */
  align-items: center; /* Center vertically */
  gap: 20px; /* Space between video and image */
  margin: 20px 0; /* Add spacing above and below */
}

.video-image-container-left iframe {
  width: 60%; /* Increase video size */
  aspect-ratio: 16 / 9; /* Maintain the aspect ratio */
  max-width: 800px; /* Ensure it doesn’t exceed container width */
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow */
}

.video-image-container-left img {
  width: 48%; /* Equal size for both video and image */
  aspect-ratio: 16 / 9; /* Maintain aspect ratio */
  max-width: 800px; /* Prevent elements from being too large */
  border-radius: 10px; /* Optional: Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow */
}

.amcl-image {
  width: 100%; /* Image takes full width of its container */
  max-width: 500px; /* Limit the maximum size */
  aspect-ratio: 16 / 9; /* Maintain aspect ratio */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Add shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover transition */
}

.amcl-image:hover {
  transform: scale(1.05); /* Slightly enlarge the image on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* Stronger shadow on hover */
}

.video-container-center {
  display: flex; /* Flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 20px auto; /* Add spacing */
}

.video-container-center iframe {
  width: 80%; /* Increase video size */
  aspect-ratio: 16 / 9; /* Maintain the 16:9 aspect ratio */
  max-width: 1000px; /* Maximum width for larger screens */
  border-radius: 10px; /* Optional: Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow */
}

.image-container-above {
  display: flex; /* Flexbox for alignment */
  justify-content: center; /* Center the image horizontally */
  margin-bottom: 20px; /* Add spacing below the image */
}

.image-container-above img {
  width: 80%; /* Set image width */
  max-width: 800px; /* Prevent it from being too large */
  aspect-ratio: auto; /* Maintain the image's aspect ratio */
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow */
}

.video-iframe {
  width: 100%; /* Video takes full width of its container */
  max-width: 500px; /* Limit the maximum size */
  aspect-ratio: 16 / 9; /* Maintain aspect ratio */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Add shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover transition */
}

.video-iframe:hover {
  transform: scale(1.05); /* Slightly enlarge the video on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* Stronger shadow on hover */
}

.video-image-container iframe,
.video-image-container img {
  width: 48%; /* Same width for both video and image */
  aspect-ratio: 16 / 9; /* Maintain consistent aspect ratio */
  max-width: 800px; /* Prevent elements from being too large */
  border-radius: 10px; /* Optional: Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow */
}

.video-container iframe {
  width: 80%; /* Use 80% of the container width for better centering */
  aspect-ratio: 16 / 9; /* Maintain the aspect ratio */
  max-width: 1200px; /* Prevent videos from being too wide */
  margin: 20px auto; /* Center the video with spacing */
  display: block;
  border-radius: 10px; /* Add slight rounding for a polished look */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add a shadow */
}

.video-container iframe,
.video-container iframe {
  width: 60%; /* Adjust width for consistent size */
  aspect-ratio: 16 / 9; /* Maintain the 16:9 ratio */
  max-width: 800px; /* Prevent video from being too large */
  margin: 0 auto; /* Center horizontally */
  display: block; /* Ensure block display for centering */
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.video-container iframe:hover,
.videos-container iframe:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-container-center iframe {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.video-container-center iframe:hover {
  transform: scale(1.05); /* Slightly enlarge the video */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* Stronger shadow on hover */
}

.video-container {
  text-align: center; /* Center the container content */
  margin-top: 20px; /* Add space above the video */
}

.videos-container iframe {
  width: 48%; /* Make each video take up 48% of the container width */
  aspect-ratio: 16 / 9; /* Maintain the 16:9 aspect ratio */
  max-width: 600px; /* Limit the width of each video */
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow */
}

.videos-container iframe:hover {
  transform: scale(1.05); /* Slightly enlarge the video on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* Stronger shadow effect on hover */
}

/* Center the video for "How about make it autonomously?" */
.video-section-center {
  display: flex; /* Flexbox for alignment */
  flex-direction: column; /* Stack subtitle and video vertically */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 20px auto; /* Add spacing */
  text-align: center; /* Center align the text */
}
.video-section-center iframe {
  width: 80%; /* Increase width to make the video larger */
  aspect-ratio: 16 / 9; /* Maintain the 16:9 aspect ratio */
  max-width: 1000px; /* Maximum width for larger screens */
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow */
  margin: 0 auto; /* Center horizontally */
  display: block; /* Ensure block-level display */
}

.videos-container {
  display: flex; /* Arrange videos in a row */
  justify-content: space-between; /* Space out the videos evenly */
  align-items: center; /* Vertically align videos */
  gap: 10px; /* Add space between videos */
  flex-wrap: nowrap; /* Prevent videos from wrapping */
  margin-top: 20px; /* Add space above the container */
}

.videos-container iframe {
  width: 48%; /* Each video takes 48% of the container width */
  aspect-ratio: 16 / 9; /* Maintain the 16:9 aspect ratio */
  max-width: 600px; /* Limit the width of each video */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Add shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Subtitle Container */
.subtitle-container {
  text-align: center;
  margin: 20px 0;
}

.subtitle-container h3 {
  font-size: 1.3rem !important; /* Reduced size */
  color: #ffcc00;
  margin-bottom: 15px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .content-header h2 {
    font-size: 24px !important;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .section-container .content-header h2 {
    font-size: 24px !important;
    text-align: center;
  }

  .section-container .content-header p {
    font-size: 18px !important;
    text-align: left;
  }

  .content-header p {
    text-align: left; /* Change from justify to left-align */
    font-size: 16px;
    line-height: 1.6;
  }

  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .gif {
    width: 90%; /* Reduced width for smaller screens */
    margin: 0 auto; /* Center align */
  }

  .responsive-iframe {
    max-width: 100%;
    height: auto; /* Adjust height dynamically */
    margin-bottom: 15px;
  }

  .subtitle-container h3 {
    font-size: 1.2rem;
  }

  .videos-container {
    justify-content: space-evenly; 
    flex-wrap: nowrap; /* Ensure side-by-side layout persists */
  }

  .videos-container iframe {
    width: 45%; /* Adjust the width to fit smaller screens */
    max-width: 45%; 
  }

  .section-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .content-header h2 {
    font-size: 24px !important;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .content-header p {
    text-align: match-parent; /* Ensure consistent alignment */
    font-size: 14px;
    line-height: 1.5;
  }

  .gif-container {
    flex-direction: column; /* Stack GIFs vertically */
    align-items: center;
    gap: 15px;
  }

  .gif {
    width: 100%; /* Full width for very small screens */
    max-width: 350px;
  }

  .video-container iframe {
    width: 100%; /* Full width for phones */
    height: 250px; /* Adjust height for smaller screens */
  }

  .videos-container {
    flex-wrap: nowrap; /* Keep videos side by side */
    justify-content: space-evenly;
    gap: 5px; /* Adjust spacing for very small screens */
  }

  .videos-container iframe {
    width: 45%; /* Reduce width further for small devices */
    max-width: 45%; /* Ensure consistent size */
  }
  .subtitle-container h3 {
    font-size: 1rem;
  }

  .section-container {
    padding: 5px;
  }
}
