.skills-title {
    text-align: center;
    font-size: 2.5rem;
    color: #ffc107;
    margin-bottom: 40px;
  }
  
  .skills-container {
    display: grid; /* 改為使用網格布局 */
    grid-template-columns: repeat(auto-fit, minmax(1000px, 1fr)); /* 自動適配區塊寬度 */
    gap: 20px; /* 增加區塊間距 */
    width: 100%; /* 確保填滿父容器 */
    padding: 20px; /* 增加內距讓區塊有呼吸空間 */
    box-sizing: border-box; /* 確保內距計入總寬度 */
}
  
  .skills-category {
    background: #1e1e2f;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    border-left: 5px solid;
    transition: transform 0.3s ease;
  }
  
  .category-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .category-icon {
    font-size: 2rem;
    color: inherit;
  }
  
  .category-title {
    font-size: 1.8rem;
    color: #ffc107;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .skills-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .skill-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
  
  .skill-label {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
    color: #ddd;
    font-weight: bold;
  }
  
  .progress-bar-container {
    flex: 3;
    background: #333;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .progress-bar {
    height: 100%;
    border-radius: 10px;
  }
  
  .skill-percentage {
    flex: 1;
    text-align: right;
    color: #ddd;
    font-weight: bold;
  }
  
  .skill-item:hover .progress-bar {
    transform: scale(1.02);
    transition: transform 0.3s ease;
  }
  
  /* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
  .skills-container {
    display: grid; /* 改為使用網格布局 */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* 自動適配區塊寬度 */
    gap: 20px; /* 增加區塊間距 */
    width: 100%; /* 確保填滿父容器 */
    padding: 20px; /* 增加內距讓區塊有呼吸空間 */
    box-sizing: border-box; /* 確保內距計入總寬度 */
}

  .skills-category {
    padding: 15px;
    border-left-width: 3px; /* Reduced border width for a cleaner look */
  }

  .category-title {
    font-size: 1.5rem;
  }

  .skill-item {
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between; /* Maintain consistent alignment */
  }

  .skill-label {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  .progress-bar-container {
    height: 8px; /* Reduced height for better proportion */
  }

  .skill-percentage {
    text-align: left;
    margin-left: 10px; /* Added margin to align with the label */
    color: #ffc107; /* Adjusted color for better visibility */
  }
}
