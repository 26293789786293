/* Section Container */
.section-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally center */
  justify-content: center; /* Vertically center */
  margin: 0 auto;
}

.section-container .content-header h2 {
  font-size: 34px;
}

.section-container .content-header p {
  font-size: 22px;
}

/* Header Styling */
.content-header {
  margin-bottom: 20px;
}

.content-header h2 {
  font-size: 32px;
  margin-bottom: 15px;
  color: #ffc107;
  font-weight: bold;
  text-align: center;
}

.content-header p {
  font-size: 20px;
  line-height: 1.8;
  color: #eaeaea;
  margin-bottom: 1em;
  text-align: justify; /* Align text for better readability */
}

/* Main Image Section */
.main-image-container {
  text-align: center;
  margin: 20px 0;
}

.image-container {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  margin: 20px 0; /* Add some spacing */
}

.single-image {
  max-width: 100%; /* Ensure the image scales to fit its container */
  height: auto; /* Maintain the aspect ratio */
  border-radius: 10px; /* Optional: add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: add a shadow effect */
}

.main-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
}

.main-image:hover {
  transform: scale(1.05);
}

/* Gallery Section */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.gallery-image {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
}

.gallery-image:hover {
  transform: scale(1.05);
}

/* Responsive Iframe */
.responsive-iframe {
  width: 100%;
  max-width: 48%; /* Side-by-side on desktop */
  aspect-ratio: 16 / 9;
  border: none;
}

/* GIF Container */
.gif-container {
  display: flex;
  justify-content: center; /* Horizontally center */
  gap: 20px; /* Add spacing between GIFs */
  margin: 20px 0;
}

.gif-below-container {
  display: flex; /* Flexbox for alignment */
  justify-content: center; /* Center the GIF horizontally */
  margin-top: 20px; /* Add space above the GIF */
}

.gif-below {
  width: 80%; /* Set the width of the GIF */
  max-width: 800px; /* Prevent it from being too large */
  aspect-ratio: auto; /* Maintain the aspect ratio */
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow */
}


/* Top GIF styling */
.top-gif-container {
  text-align: center;
  margin: 20px 0;
  justify-content: center; 
}

.top-gif {
  width: 80%;
  max-width: 800px;
  justify-content: center; 
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.image-description {
  text-align: center;
  font-size: 18px;
  color: #eaeaea;
  margin: 15px 0;
}


/* Two images side by side */
.gif-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.gif {
  width: 45%; /* Adjust as needed */
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}


/* Bottom GIF styling */
.bottom-gif-container {
  text-align: center;
  margin: 30px 0;
}

.bottom-gif {
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* 確保所有 GIF 包括 .gif, .single-image, .top-gif 都有一致的 hover 效果 */
.gif, .single-image, .top-gif {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑過渡 */
  border-radius: 10px; /* 可選，統一圓角 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 基礎陰影 */
}

.gif:hover, .single-image:hover, .top-gif:hover {
  transform: scale(1.05); /* 放大效果 */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* 增強陰影 */
  filter: brightness(1.2); /* 提升亮度 */
}



.gif {
  width: 48%; /* Consistent width */
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.gif:hover {
  transform: scale(1.05); /* 放大效果 */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* 陰影加強效果 */
  filter: brightness(1.2); /* 提升亮度 */
}

/* Video Container */
.video-container iframe {
  width: 80%; /* Use 80% of the container width for better centering */
  aspect-ratio: 16 / 9; /* Maintain the aspect ratio */
  max-width: 1200px; /* Prevent videos from being too wide */
  margin: 20px auto; /* Center the video with spacing */
  display: block;
  border-radius: 10px; /* Add slight rounding for a polished look */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add a shadow */
}

.videos-container iframe {
  width: 48%; /* Each video takes 48% of the container width */
  aspect-ratio: 16 / 9; /* Maintain a 16:9 aspect ratio */
  max-width: 600px; /* Limit maximum width for consistency */
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add a shadow */
}

.videos-container {
  display: flex; /* Align videos side by side */
  justify-content: space-around; /* Space out the videos evenly */
  align-items: center; /* Align videos vertically */
  gap: 20px; /* Add space between the videos */
  flex-wrap: nowrap; /* Prevent wrapping to the next line */
  margin-top: 20px; /* Add spacing above the videos */
}

/* Subtitle Container */
.subtitle-container {
  text-align: center;
  margin: 20px 0;
}

.subtitle-container h3 {
  font-size: 1.3rem !important; /* Reduced size */
  color: #ffcc00;
  margin-bottom: 15px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .section-container .content-header h2 {
    font-size: 24px !important;
    text-align: center;
  }

  .section-container .content-header p {
    font-size: 18px !important;
    text-align: left;
  }

  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .gif {
    width: 90%; /* Reduced width for smaller screens */
    margin: 0 auto; /* Center align */
  }

  .responsive-iframe {
    max-width: 100%;
    height: auto; /* Adjust height dynamically */
    margin-bottom: 15px;
  }

  .subtitle-container h3 {
    font-size: 1.2rem;
  }

  .videos-container {
    flex-wrap: wrap; /* Allow videos to stack vertically */
    flex-direction: column; /* Stack videos top to bottom */
    align-items: center; /* Center the videos */
  }

  .videos-container iframe {
    width: 90%; /* Use 90% of the container width for better spacing */
    max-width: 100%; /* Ensure it doesn’t exceed the container */
    margin-bottom: 20px; /* Add spacing between the videos */
  }

  .section-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .section-container .content-header h2 {
    font-size: 24px !important;
    text-align: center;
  }

  .section-container .content-header p {
    font-size: 18px !important;
    text-align: left;
  }
  
  .gif-container {
    flex-direction: column; /* Stack GIFs vertically */
    align-items: center;
    gap: 15px;
  }

  .gif {
    width: 100%; /* Full width for very small screens */
    max-width: 350px;
  }

  .video-container iframe {
    width: 100%; /* Full width for phones */
    height: 250px; /* Adjust height for smaller screens */
  }

  .subtitle-container h3 {
    font-size: 1rem;
  }

  .section-container {
    padding: 5px;
  }
}
