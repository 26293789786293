/* Portfolio Container */
#portfolio {
  padding: 30px 10px; /* Reduced padding for smaller devices */
  background: #0b1120;
  color: #f8f9fa;
}

/* Header Styling */
h2 {
  text-align: center;
  font-size: 1.8rem; /* Adjust font size for mobile */
  color: #ffc107;
  margin-bottom: 15px;
}

/* Category Buttons */
/* Category Buttons */
.category-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap */
  justify-content: center;
  gap: 10px; /* Add spacing between buttons */
  margin-bottom: 20px;
}

.category-button {
  padding: 10px 18px; /* Slightly larger padding for better tap area */
  font-size: 1rem; /* Keep font size readable */
  background-color: #112240; /* Dark background for buttons */
  color: #fff; /* White text */
  border: 2px solid #ffc107; /* Add border for better visibility */
  border-radius: 20px; /* Rounded corners */
  cursor: pointer;
  transition: transform 0.2s, background-color 0.3s;
}

.category-button:hover {
  background-color: #ffc107; /* Bright yellow on hover */
  color: #121212; /* Dark text on hover */
}

.category-button.active {
  background-color: #ffae00; /* Highlight active button */
  color: #fff; /* White text for active button */
  transform: scale(1.1); /* Slight zoom to indicate focus */
}

/* Project Grid */
/* Project Grid */
.project-grid {
  display: grid;
  grid-template-columns: 1fr; /* Stacked layout for mobile */
  gap: 15px; /* Reduce spacing for mobile view */
}

/* Project Card */
.project-card {
  width: 100%; /* Full width on mobile */
  background: #112240; /* Dark card background */
  border-radius: 10px;
  padding: 20px; /* Slightly larger padding for better readability */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  transition: transform 0.2s, box-shadow 0.2s;
}

.project-card:hover {
  transform: translateY(-8px); /* Lift effect on hover */
  box-shadow: 0px 8px 16px rgba(255, 193, 7, 0.3); /* Highlight shadow */
}


/* Tags */
.tags {
  margin-top: 15px; /* Add spacing above tags */
  display: flex;
  flex-wrap: wrap; /* Wrap tags for small screens */
  gap: 8px; /* Add spacing between tags */
}

.tag {
  background: #ffc107; /* Yellow background for tags */
  color: #121212; /* Dark text */
  padding: 6px 10px; /* Slightly larger padding for better visibility */
  border-radius: 6px; /* Rounded corners for tags */
  font-size: 0.9rem; /* Maintain readability */
  font-weight: bold; /* Emphasize tags */
  transition: transform 0.2s, background-color 0.3s;
}

.tag:hover {
  background: #ffdd57; /* Lighter yellow on hover */
  transform: scale(1.1); /* Slight zoom effect */
}

/* Filter Buttons Container */
.filter-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Individual Filter Button Styles */
.filter-buttons button {
  background-color: #ffc107; /* Bright yellow background */
  color: #121212; /* Dark text for contrast */
  font-size: 1rem; /* Adjust text size */
  padding: 10px 20px; /* Spacing inside the button */
  border: none;
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.3s ease-in-out;
}

/* Hover Effect */
.filter-buttons button:hover {
  background-color: #ffdd57; /* Slightly lighter yellow */
  transform: scale(1.05); /* Slight zoom effect */
}

/* Active Button State */
.filter-buttons button.active {
  background-color: #ffae00; /* Orange to highlight active button */
  color: #fff; /* White text for better contrast */
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  h2 {
    font-size: 1.4rem; /* Adjust font size for very small screens */
  }

  .category-button {
    padding: 8px 12px; /* Compact button size for mobile */
    font-size: 0.8rem; /* Smaller text */
  }

  .project-card {
    padding: 15px; /* Reduce padding for compact view */
    font-size: 0.9rem; /* Slightly smaller text size */
  }

  .tag {
    font-size: 0.7rem; /* Smaller tags for extra-small screens */
    padding: 4px 8px; /* Compact padding */
  }
}
