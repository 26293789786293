* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Include padding in element dimensions */
  }
  
  body, html {
    height: auto; /* Allow the document to grow with content */
    min-height: 100vh; /* Ensure full viewport height */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  section {
    padding: 50px 20px; /* Add consistent padding to all sections */
    box-sizing: border-box; /* Ensure padding doesn't cause overflow */
  }
  
  h1, h2, h3, h4, h5, h6, p {
    margin: 0; /* Remove default margins */
    line-height: 1.5; /* Set consistent line height */
    word-wrap: break-word; /* Prevent long words from breaking layout */
  }
  